/* eslint-disable camelcase */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/anchor-is-valid */
// import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useStaticQuery, graphql, navigate, StaticQuery } from 'gatsby';
import { connect } from 'react-redux';
import { useFormik } from 'formik';
import * as yup from 'yup';
import {
  makeStyles,
  Typography,
  Grid,
  FormControl,
  OutlinedInput,
  IconButton,
  InputLabel,
  FormHelperText,
  Link,
  Button,
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';

import { setCurrentUser } from 'src/api/_common';

import api from 'src/services/axios';

import Alert from 'src/components/alert';
import RichText from 'src/components/richText';
import PhoneInput from 'src/components/phoneInput';
import { currentStep, nextStep } from 'src/features/onboarding/slice';
import StepsNavigation, { NextButton } from 'src/features/onboarding/stepsNavigation';

import onboarding from 'src/api/onboarding';

const useStyles = makeStyles(theme => ({
  form: {
    maxWidth: 700,
    margin: 'auto',
  },
  controlsWrapper: {
    maxWidth: 474,
    margin: 'auto',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  icon: {
    color: theme.palette.primary.main,
  },
  apiAlert: {
    margin: theme.spacing(2, 0),
  },
  link: {
    cursor: 'pointer',
  },
}));

const initialValues = {
  email: '',
  company: '',
  phone: '',
  password: '',
  plan: '',
  countryCodeId: '',
};

const Step1 = props => {
  const classes = useStyles();

  const [countries, setCountries] = useState([]);
  const [error, setError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isCreatedUser, setIsCreatedUser] = useState(false);

  const validationSchema = yup.object().shape({
    countryCodeId: yup.string(),
    email: yup
      .string()
      .email()
      .required('This field is required.'),
    company: yup
      .string()
      .required('This field is required.'),
    phone: yup
      .string()
      .required('This field is required.')
      .test('phone_check', function (value) {
        let pattern = '';

        const countryCodeIdValue = this.resolve(yup.ref('countryCodeId'));
        const phoneValue = this.resolve(yup.ref('phone'));
        const country = countries.find(countryItem => countryItem.country_code === countryCodeIdValue);

        if (!country?.reg_pattern || !phoneValue || countries.length < 1) {
          return true;
        }

        if (country?.reg_pattern) {
          pattern = country.reg_pattern.replace('/^', '^').replace('$/', '$');
        }

        if (pattern != '' && !phoneValue.replaceAll('-', '').match(new RegExp(pattern), 'Incorrect phone number.')) {
          return this.createError({
            message: `Incorrect phone number.`,
            path: 'phone',
          });
        } else {
          return true;
        }
      }),
    password: yup
      .string()
      .min(8, 'Password must be contain at least 8 characters')
      .matches(/[0-9]/, 'Password requires at least one digit')
      .matches(/[a-z]/, 'Password requires at least one lowercase letter')
      .matches(/[A-Z]/, 'Password requires at least one uppercase letter')
      .matches(/[^\w]/, 'Password requires at least one special symbol')
      .required('Password is required'),
    plan: null, //.required(),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async values => {
      const { status, message } = await onboarding.registerUser(values);

      if (status === 'success') {
        setIsCreatedUser(true);
        // sessionStorage.setItem('step4', JSON.stringify({ ...formik.values }));

        // props.nextStep();
      } else {
        setError(message);
      }
    },
  });

  const prismicContent = useStaticQuery(graphql`
    query {
      allPrismicOnboarding {
        edges {
          node {
            data {
              title1 {
                html
                text
                raw
              }
              text1 {
                html
                text
                raw
              }
            }
          }
        }
      }
    }
  `);

  const prismicData = prismicContent.allPrismicOnboarding.edges[0].node.data;

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmitSearch = (event) => {
    event.preventDefault();

    if (formik.isValid) {
      formik.submitForm();
    }
  };

  const handleChangePhone = (fieldName, value, countryCodeId) => {
    formik.setValues({ ...formik.values, phone: value, countryCodeId }, true);
  };

  const handleGetCountriesList = (data) => {
    if (data.length < 0) {
      return true;
    }

    setCountries(data);

    return true;
  };

  useEffect(() => {
    props.currentStep({ activeStep: 1 });
  }, []);

  useEffect(() => {
    const plan = sessionStorage.getItem('plan');

    if (!plan || '' === plan) {
      formik.setFieldValue('plan', null);
    }
    formik.setFieldValue('plan', plan);
  }, []);


  useEffect(async () => {
    if (!isCreatedUser) {
      return;
    }
    const { data: me } = await api.get('/me');
    setCurrentUser(me.data.user);

    $FPROM.trackSignup(
      {
        email: me.data.user.email
      },
      function () {
        console.log('Callback received!')
      }
    );

    if (!window.dataLayer) {
      window.dataLayer = [];
    }


    window.dataLayer.push(
      {
        "identity": {
          "account": {
            "id": me.data.user.id,
          },
          "user": {
            "id": me.data.user.id,
          },
        },
        "context": {
          "staging": process.env.DEVELOPMENT_LOGOUT,
          "impersonating": "false",
          "role": me.data.user.user_role,// "user"
        },
        "accountProperties": {
          "created_at": new Date(),
        }
      }
    );

    window.dataLayer.push({
      "event": "signup",
      "context": {
        "staging": process.env.DEVELOPMENT_LOGOUT,
      },
      "accountProperties": {
        "created_at": Date.now(),
      }
    });

    sessionStorage.setItem('step4', JSON.stringify({ ...formik.values }));

    props.nextStep();
  }, [isCreatedUser]);

  const openHelpChat = () => {
    const elements = document.getElementById('customerly-container').childNodes[0].childNodes;
    if (elements.length !== 1) {
      return;
    }

    elements[0].childNodes[0].childNodes[0].click();
  }
  return (
    <>
      <form className={classes.form}>
        <Grid container>
          <Typography variant="h1">{prismicData.title1.text}</Typography>

          <Typography variant="subtitle1">
            <RichText render={prismicData.text1.raw} />
          </Typography>
        </Grid>

        <FormHelperText component="div" className={classes.apiAlert}>
          {error && <Alert severity="error">{error}</Alert>}
        </FormHelperText>

        <div className={classes.controlsWrapper}>

          <FormControl variant="outlined" fullWidth margin="normal">
            <InputLabel htmlFor="email">Enter your email</InputLabel>

            <OutlinedInput
              id="email"
              label="Enter your email"
              name="email"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
              error={formik.errors.email && formik.touched.email}
            />

            {formik.errors.email && formik.touched.email ? (
              <FormHelperText component="div">
                <Alert severity="error">{formik.errors.email}</Alert>
              </FormHelperText>
            ) : null}
          </FormControl>

          <FormControl variant="outlined" fullWidth margin="normal">
            <InputLabel htmlFor="company">Enter your company name</InputLabel>

            <OutlinedInput
              id="company"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              label="Enter your company name"
              name="company"
              value={formik.values.company}
              error={formik.errors.company && formik.touched.company}
            />

            {formik.errors.company && formik.touched.company ? (
              <FormHelperText component="div">
                <Alert severity="error">{formik.errors.company}</Alert>
              </FormHelperText>
            ) : null}
          </FormControl>

          <FormControl variant="outlined" fullWidth margin="normal">
            <PhoneInput
              country
              name="phone"
              value={formik.values.phone}
              countriesList={handleGetCountriesList}
              onChange={handleChangePhone}
              onBlur={formik.handleBlur}
              error={formik.errors.phone && formik.touched.phone}
            />

            {formik.errors.phone && formik.touched.phone ? (
              <FormHelperText component="div" className={classes.textHelper}>
                <Alert severity="error">{formik.errors.phone}</Alert>
              </FormHelperText>
            ) : null}
          </FormControl>

          <Typography component="div" variant="caption" style={{marginTop:'8px',lineHeight:'15px'}}>
            <div style={{marginBottom:'5px'}}>
              By providing your business phone number and click "Next", you agree to receive business-related calls and text messages from Leverly, including calls and text messages made using an automatic telephone dialing system or pre-recorded or artificial voice messages, at the phone number you provide. Message and data rates may apply. Consent is not a condition of purchase.
            </div>
            <div style={{marginBottom:'5px'}}>
              For more information, please read our Privacy Policy and Terms of Servise.
            </div>
            <div style={{marginBottom:'5px'}}>
              You can opt-put at any time by replying "STOP" to any text message you receive from us. For help, reply "HELP".
            </div>
            <div>
              If your leads and customers are outside of the US or Canada, please ask{' '} <Link className={classes.link} onClick={openHelpChat}>Customer Success</Link> to help you set up your international account
            </div>
          </Typography>

          <FormControl variant="outlined" fullWidth margin="normal" style={{marginBottom: '2px'}}>
            <InputLabel htmlFor="password">Create your password</InputLabel>

            <OutlinedInput
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              label="Create your password"
              name="password"
              type={showPassword ? 'text' : 'password'}
              value={formik.values.password}
              error={formik.errors.password && formik.touched.password}
              endAdornment={
                <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} edge="end">
                  {showPassword ? <VisibilityOff className={classes.icon} /> : <Visibility className={classes.icon} />}
                </IconButton>
              }
            />

            <FormHelperText component="div">
              {formik.errors.password && formik.touched.password ? (
                <Alert variant="filled" severity="error">
                  {formik.errors.password}
                </Alert>
              ) : null}
            </FormHelperText>
          </FormControl>

          <Typography component="div" variant="caption">
            <div style={{width:'100%',display:'block',lineHeight:'15px'}}>
              <ul style={{columns:2,marginTop:0,paddingLeft:'10px'}}>
                <li>8 characters minimum</li>
                <li>One lowercase letter</li>
                <li>One uppercase letter</li>
                <li>One special symbol</li>
                <li>One number</li>
              </ul>
            </div>
          </Typography>
        </div>
        <Button type="submit" onClick={(event) => handleSubmitSearch(event)} style={{ display: 'none' }}></Button>
      </form>

      <StepsNavigation>
        <NextButton onClick={formik.handleSubmit} disabled={!formik.isValid} />
      </StepsNavigation>
    </>
  );
};

const mapDispatchToProps = { currentStep, nextStep };

export default connect(null, mapDispatchToProps)(Step1);
